import styled from "styled-components";
import {theme} from "/helpers/theme";
import {Container} from "../../../styles/GlobalStyle";


export const VacancyFormWrapper = styled.div`
  &.vacancy__question-wp {
    padding: 88px 100px
  }

  &.vacancy__question-wp .p2 {
    margin-top: 15px
  }
  
  
  form {
    margin-top: 28px;
  }
  
  input, textarea, .ant-select-selector {
    border: 1px solid ${ ({ withoutBorder }) => {
      return withoutBorder ? '#FFFFFF' : '#8A8A89'
    } } !important;
  }
  
  .ant-select-selection-search input {
    border: none!important;
    padding: 0;
  }
  
  .ant-row .ant-form-item {
    margin: 0;
  }


  .vacancy__form {
    margin-top: 23px
  }

  .vacancy__form input,
  .vacancy__form textarea {
    border-color: #8A8A89
  }

  .vacancy__form-inputs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .vacancy__form-left {
    width: 49%
  }

  .vacancy__form-right {
    width: 48.4%
  }

  .vacancy__form .input-block input,.vacancy__form .input-block textarea {
    max-width: 600px;
    border-color: transparent
  }

  .vacancy__form .input-block.wrong input,.vacancy__form .input-block.wrong textarea {
    border-color: #EF6957
  }

  .vacancy__form .input-block textarea {
    height: 204px;
    resize: none
  }

  .vacancy__form-bottom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 13px
  }

  .vacancy__form-bottom-left {
    width: 49%;
    padding-top: 21px;
    position: relative;
    padding-right: 21px
  }

  .vacancy__form-bottom-left button {
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #8A8A89;
    transition: color .25s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: -4px
  }

  .vacancy__form-bottom-left button svg {
    margin-right: 10px
  }

  .vacancy__form-bottom-left .remove {
    position: relative;
    top: -5px
  }

  .vacancy__form-bottom-left .remove svg {
    color: #8A8A89
  }

  .vacancy__form-bottom-right {
    width: 48.4%
  }

  .vacancy__form-bottom-right .btn {
    margin-top: 32px;
    height: 64px;
    width: 263px;
    font-size: 20px;
    line-height: 24px;
    padding-left: 25px
  }

  .vacancy__form-bottom-right .btn svg {
    fill: #fff;
    margin-left: 14px;
    margin-top: 1px
  }

  .vacancy__form-bottom .error-text {
    font-size: 9px;
    line-height: 11px;
    color: #EF6957;
    display: block;
    margin-top: 5px
  }
  
  @media (max-width: 1440px) {
    .vacancy__form-bottom-right .btn,.vacancy__text .btn2 {
      height: 56px
    }
    
    &.vacancy__question-wp {
      padding: 88px 30px
    }
  }

  @media (min-width: 991px) {
    .vacancy__form-bottom-left button:focus,.vacancy__form-bottom-left button:hover {
      color:#EF6957
    }
  }
  
  @media (max-width: 990px) {
    &.vacancy__question-wp {
      padding: 40px 30px
    }
    
    .vacancy__form {
      margin-top: 21px;
    }

    .vacancy__form {
      margin-top: 21px
    }

    .vacancy__form .input-block textarea {
      height: 120px
    }

    .vacancy__form-bottom {
      margin-top: 16px
    }

    .vacancy__form-bottom-left {
      padding-top: 0
    }

    .vacancy__form-bottom-left button {
      font-size: 14px;
      line-height: 17px;
      margin-left: 0
    }

    .vacancy__form-bottom-left button svg {
      width: 15px;
      height: 16px
    }

    .vacancy__form-bottom-left .remove {
      top: 0
    }

    .vacancy__form-bottom-right .btn {
      margin-top: 12px;
      height: 48px;
      font-size: 14px;
      line-height: 16px
    }
  }

  @media (max-width: 760px) {
    &.vacancy__question-wp {
      padding: 28px 20px 36px
    }
  }


  @media (max-width: 575px) {

    &.vacancy__question-wp h2+.p2 {
      font-size: 14px;
      line-height: 20px;
      margin-top: 12px
    }
    
    .vacancy__form-right {
      margin-top: 16px;
    }

    .vacancy__form-inputs {
      display: block
    }

    .vacancy__form-left,.vacancy__form-right {
      width: 100%
    }

    .vacancy__form-bottom {
      display: block;
      margin-top: 14px
    }

    .vacancy__form-bottom-left,.vacancy__form-bottom-right {
      width: 100%
    }

    .vacancy__form-bottom-left button svg {
      margin-right: 13px
    }

    .vacancy__form-bottom-right {
      margin-top: 16px
    }

    .vacancy__form-bottom-right .btn {
      margin-top: 15px;
      width: 100%;
      padding-top: 8px
    }

    .vacancy__form-load {
      padding-left: 0
    }

    .vacancy__form .input-block:last-of-type {
      margin-bottom: 0
    }

    .vacancy__form .input-block textarea {
      height: 130px
    }
  }
  
`;

export const ModalUploadButton = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #8A8A89;
  display: flex; 
  align-items: center;
  cursor: pointer;
  transition: ${theme.animation.transition};
  
  img {
    margin-right: 10px;
  }
  
  &:hover {
    color: #EF6957;
  }
  
  @media (max-width: 990px) {
    img {
      width: 16px;
      height: 16px;
    }
  
    font-size: 14px;
  }
`;