import styled from "styled-components";

export const FooterWrapper = styled.footer`
  &.footer {
    border-top: 1px solid rgba(50,50,50,.2);
    padding-top: 65px;
    padding-bottom: 40px
  }

  &.footer .container {
    max-width: 1790px
  }

  .footer__top {
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .footer__top,.footer__top-left {
    display: -ms-flexbox;
    display: flex
  }

  .footer__top-left a {
    margin-right: 68px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    transition: .25s;
    border-bottom: 1px solid transparent
  }

  .footer__top-left a:hover {
    border-bottom: 1px solid #EF6957
  }

  .footer__top-left a:last-of-type {
    margin-right: 0
  }

  .footer .header__contacts div {
    display: inline-block
  }

  .footer__bottom {
    margin-top: 44px
  }

  .footer__bottom .p2 {
    opacity: .2
  }

  @media (max-width: 1440px) {
    .footer__top-left a {
      font-size:14px;
      line-height: 24px;
      margin-right: 25px
    }
  }

  @media (max-width: 1140px) {
    .footer__top {
      display:block
    }

    .footer__top-left {
      margin-bottom: 20px
    }
  }

  @media (max-width: 760px) {
    .footer__top-left {
      display:block
    }

    .footer__top-left a {
      display: block;
      margin-bottom: 20px;
      margin-left: 0;
      white-space: normal
    }
  }

  @media (max-width: 575px) {
    &.footer {
      padding-top:28px;
      padding-bottom: 27px
    }

    &.footer .header__phone {
      margin-right: 0
    }

    &.footer .header__phone svg {
      position: relative;
      top: 4px
    }

    &.footer .header__contacts {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center
    }

    &.footer .header__contacts div {
      margin-top: 25px
    }

    &.footer .header__contacts div svg {
      width: 20px;
      height: 22px
    }

    &.footer .header__fb {
      margin-left: 23px;
      margin-right: 22px
    }

    .footer__top-left {
      margin-bottom: 11px
    }

    .footer__top-left a {
      margin-left: auto;
      margin-right: auto;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 19px
    }

    .footer__top-left a:last-of-type {
      margin-bottom: 0
    }

    .footer__bottom {
      margin-top: 30px
    }

    .footer__bottom .p2 {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      text-align: center
    }
  }
  
  
`;

export const FooterInfo = styled.div`
  .header__contacts {
    width: 100px;
    
    .header__links {
      width: 64px;
      display: flex; 
      justify-content: flex-end
    }

    @media (max-width: 990px) {
      width: auto;

      .header__links {
        width: auto;
        justify-content: flex-start;
      }
    }
    
    @media (max-width: 760px) {
      width: auto;
    }
  }
`;

export const FooterLinks = styled.div`
  a {
    margin-bottom: 10px;
  } 
`;

export const FooterBottom = styled.div`
`;