import React, {useEffect, useState} from 'react'
import {CookiePolicyContainer, CookiePolicyWrapper} from '../../styles/GlobalStyle';
import Cookies from "js-cookie";
import {cookieMaxAge} from '../../helpers/common';

const CookiePolicyBox = () => {
  const [showPolicyText, setShowPolicyText] = useState(false);

  useEffect(() => {
    if (process.browser) {
      setShowPolicyText(!Cookies.get('vl_cp'));
    }
  }, []);

  const acceptCookiePolicy = () => {
    Cookies.set('vl_cp', true, { expires: 9999 });
    setShowPolicyText(false);
  }

  return (
    <>
      {
        showPolicyText && (
          <CookiePolicyWrapper>
            <CookiePolicyContainer>
              <div className="podp">
                Продолжая использовать сайт, Вы даете согласие ООО «Необычные Вакансии», ОГРН 1217700307747, ИНН/КПП 9701180397/770101001, 101000, город Москва, Архангельский пер, д. 9, этаж подвал помещ. III, ком. 6, рм8ж (далее – «Оператор») на обработку файлов cookies и пользовательских данных в целях обеспечения бесперебойной работы сайта, улучшения пользовательского опыта, ведения статистики посещений сайта, составление профиля, таргетирования товаров в соответствии с интересами пользователя сайта. Если Вы не хотите, чтобы Ваши вышеперечисленные данные обрабатывались, просим отключить обработку файлов cookies и сбор пользовательских данных в настройках Вашего браузера или покинуть сайт.
              </div>
              <a className="btn" onClick={acceptCookiePolicy}>Ок</a>
            </CookiePolicyContainer>
          </CookiePolicyWrapper>
        )
      }
    </>
  )
}

export default CookiePolicyBox;