import React from 'react';
import {FooterBottom, FooterInfo, FooterLinks, FooterWrapper} from "./Footer.style";
import Image from "next/image";

const Footer = () => {
  return (
    <FooterWrapper className="footer">
      <div className="container">
        <div className="footer__wp">
          <FooterInfo className="footer__top">
            <FooterLinks className="footer__top-left" style={{ flexWrap: 'wrap' }}>
              <a href="/uploads/politika_obrabotki_personalnyh_dannyh.pdf"
                  rel="noreferrer" target="_blank">Политика обработки персональных данных</a>
              <a href="/uploads/soglasie_na_obrabotku_personalnyh_dannyh.pdf"
                  rel="noreferrer" target="_blank">Согласие на обработку персональных данных</a>
              <a href="/uploads/dogovor_okazaniya_uslug.pdf"
                 rel="noreferrer" target="_blank">Договор оказания услуг</a>
              <a href="/uploads/soglasie_na_poluchenie_novostnoj_i_reklamnoj_rassylki.pdf"
                  rel="noreferrer" target="_blank">Согласие на получение новостной и рекламной рассылки</a>
              <a href="/uploads/polzovatelskoe_soglashenie.pdf"
                  rel="noreferrer" target="_blank">Пользовательское соглашение</a>
              <a href="/uploads/politika_obrabotki_fajlov_cookie.pdf"
                  rel="noreferrer" target="_blank">Политика обработки файлов cookie</a>
            </FooterLinks>
            <div className="header__contacts">
              <div className="header__links">
                <a className="header__tg" href="https://t.me/vacanciesbest" target="_blank" rel="noopener noreferrer">
                  <Image src={`/images/telegram-icon.svg`} width={16} height={16} />
                </a>
                {/*<a className="header__fb" href="https://facebook.com/fkncy" target="_blank" rel="noopener noreferrer">*/}
                {/*  <Image src={`/images/facebook-icon.svg`} width={16} height={16} />*/}
                {/*</a>*/}
              </div>
            </div>
          </FooterInfo>

          <FooterBottom className="footer__bottom">
            <div className="p2">© 2020-{ new Date().getFullYear() } Facancy (ООО "Необычные вакансии"), Все права защищены.</div>
          </FooterBottom>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default Footer;