import styled from "styled-components";

export const LayoutWrapper = styled.section`

  &.wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow-y: hidden;
    
    &.sticky {
      overflow: unset
    }
    
    main {
      position: relative;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      overflow: visible
    }

    .footer {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto
    }
  }
`;

export const MessageModalWrapper = styled.div`
  .p2-centered {
    text-align: center;
    padding-top: 12px;
  }
  
  h2 {
    text-align: center;
  }
`;