import styled from "styled-components";
import {theme} from "../../helpers/theme";

export const HeaderWrapper = styled.header`
  background: ${ ({ backgroundColor }) => backgroundColor ? backgroundColor : theme.colors.white };

  &.header {

    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    
    //&.header_main,
    //&.header_main-red {
    //  background: #F5F7FB
    //}

    &.header_main-red {
      .header__wp {
        padding-left: 0
      }

      .header__logo svg {
        fill: #EF6957
      }
    }
    
    //&.header_main-red_white {
    //  background: #ffffff
    //}
  }

  svg {
    fill: #323232
  }

  a {
    color: #323232;
    transition: .25s;
    &:hover {
      opacity: .8
    }
  }

  .header-button {
    height: auto;
    margin-left: 26px;
    font-size: 12px;
    min-width: 88px;
    padding: 5px 10px 4px;
  }

  .header__logo {
    line-height: 0
  }

  .header__wp {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 20px;
    padding-top: 24px;
    padding-bottom: 19px
  }

  .header__wp {
    padding-left: 0;
  }

  .header__logo img {
    height: 40px;
  }

  .header__fb {
    margin-left: 27px;
    margin-right: 26px;

  }

  .header__phone {
    margin-right: 52px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px
  }

  .header__phone svg {
    position: relative;
    margin-right: 8px;
    top: 2.5px
  }

  .header__lk {
    margin-left: 26px;
    display: inline-block;
    cursor: pointer;
    position: relative
  }

  .header__lk svg {
    transition: .25s
  }

  .header__lk:hover svg.person-icon svg {
    fill: #EF6957
  }

  .header__lk-drop {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: -148px;
    bottom: -181px;
    min-width: 308px;
    background: #ffffff;
    box-shadow: 0 8px 20px rgba(0,0,0,.1);
    padding: 21px 0;
    z-index: 14;
    transition: .25s
  }

  .header__lk-drop a {
    height: 42px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    transition: .25s;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #323232;
    padding: 0 23px
  }

  .header__lk-drop a.dot {
    position: relative
  }

  .header__lk-drop a.dot:before {
    content: "";
    position: absolute;
    top: 40%;
    right: 19px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4985DF
  }

  .header__lk-drop svg {
    margin-right: 15px
  }

  .header__lk-drop_open .header__lk-drop {
    visibility: visible;
    opacity: 1
  }

  .header__lk-drop svg {
    fill: #212121
  }

  .header__lk-drop .drop-item:hover svg {
    fill: #2F80ED
  }

  .header__lk-drop .drop-item:hover span {
    color: #2F80ED
  }

  .header__lk-drop:before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background: #ffffff;
    width: 16px;
    height: 16px;
    top: -8px;
    left: 48%
  }

  .header__lk_dot:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4985DF;
    top: 3px;
    right: -5px
  }

  .right-content-buttons {
    display: flex;
    align-items: center;
  }
  

  @media (max-width: 1750px) {
    .header__lk-drop {
      left:-270px
    }

    .header__lk-drop:before {
      left: 88%
    }
  }

  @media (max-width: 1140px) {
    .header__lk-drop {
      min-width:280px;
      width: 280px;
      left: -260px;
      bottom: -146px;
      padding-top: 14px;
      padding-bottom: 14px
    }

    .header__lk-drop:before {
      left: 93%
    }

    .header__lk-drop a {
      height: 36px;
      font-size: 12px;
      line-height: 15px
    }

    .header__lk-drop svg {
      width: 18px;
      height: 20px
    }
  }
  
  @media (max-width: 1440px) {
    .header__phone {
      margin-right:30px
    }
  }

  @media (max-width: 1440px) {
    &.header_main-red_white {
      box-shadow:0 2px 6px rgba(60,59,90,.04),0 2px 40px rgba(60,59,90,.04)
    }
  }

  @media (min-width: 991px) {
    .header__lk-drop a:hover {
      background:#F5F7FB
    }

    .header__lk-drop a:hover span {
      color: #2F80ED;
      font-weight: 600
    }

    .header__lk-drop a:hover svg path {
      fill: #2F80ED
    }
  }

  @media (max-width: 760px) {
    .header__wp {
      padding-left:0
    }

    .header__logo img {
      height: 26px;
    }

    .header__logo svg {
      width: 125px;
      height: 26px
    }
  }
  

  @media (max-width: 575px) {
    &.header .header__fb,
    &.header .header__in,.header .header__tg {
      display: none;
      margin-left: 21px;
    }

    &.header .header__phone {
      margin-right: -4px
    }

    &.header .header__phone span {
      display: none
    }

    .header__wp {
      padding-top:17px
    }

    .header__phone {
      font-size: 12px;
      line-height: 18px
    }

    .header__phone svg {
      top: 0
    }

    .header__lk {
      margin-left: 18px;
      margin-right: 2px
    }

    .header__lk svg {
      transform: scale(1.2)
    }
  }
`;

export const HeaderContent = styled.div`
  padding-left: 0;
`;


export const HeaderContacts = styled.div`

`;

export const HeaderSocialLink = styled.div`
`;

export const Logo = styled.img`
  max-height: 40px !important;
`;
