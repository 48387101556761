import {Button, Form, Input, Space} from "antd";
import React, {useEffect, useMemo, useRef, useState} from "react";
import Countdown from "react-countdown";
import MessageModal from "../Layout/MessageModal";
import api from "../../api";
import {rules, SUCCESS} from "../../helpers/common";
import {ManageVerificateEmailButtons, ManageVerificateEmailWrapper} from "../Page/Manage/Manage.style";
import {useRouter} from "next/router";

const VerificateCodeModal = ({modalVisible,
                              setModalVisible,
                              verificationNonce,
                              onSuccess,
                              successModalMessage = 'Изменения успешно сохранены',
                              modalTitle = 'Для того, чтобы сохранить ваши изменения, введите код, который был направлен на ваш email:',
                              resendUrl = '/auth/verification/resend_code',
                              sendCodeUrl = '/auth/verification/confirm_operation'

}) => {

  const router = useRouter();
  const [vem_form] = Form.useForm();
  const [disableResend, setDisableResend] = useState(true);
  const [message, setMessage] = useState(null);
  //const [dateNow, setDateNow] = useState(null);
  const [messageVisible, setMessageVisible] = useState(false);
  const codeInput = useRef();

  const countdownTimer = useRef();

  const dateNow = useMemo(() => Date.now(), [modalVisible, disableResend])

  const runTimer = () => {
    setDisableResend(true);
    if (countdownTimer?.current) {
      countdownTimer.current.start();
    }
  }

  useEffect(() => {
    if (modalVisible) {
      runTimer();
      if (codeInput?.current) {
        codeInput?.current.focus()
      }
    } else {
      if (countdownTimer?.current) {
        countdownTimer.current.stop();
      }
      if (vem_form) {
        vem_form.resetFields()
      }
    }
  }, [modalVisible]);

  const sendVerificationCode = async () => {
    const values = {...vem_form.getFieldsValue(), verification_nonce: verificationNonce};

    await api.post(sendCodeUrl, {}, values, null, false, true)
      .then(({data}) => {
        if (successModalMessage) {
          setMessage(successModalMessage);
          setMessageVisible(true);
        }
        setModalVisible(false);
        vem_form.resetFields();
        router.replace(router.asPath);
        if (onSuccess) {
          onSuccess(data)
        }
      })
      .catch(error => {
        if (error.code === 1060) {
          setTimeout(() => {
            router.push('/vs-refill')
          }, 3000)
        }
        setMessage((error?.code === 1039) ? 'Неправильный код' : error.code === 1060 ? 'Недостаточно средств для покупки. Вы будете перенаправлены на страницу пополнения средств' : 'Что-то пошло не так, повторите ещё раз');
        setMessageVisible(true);
      });
  }

  const resendCode = async () => {
    vem_form.resetFields();
    await api.post(resendUrl, {}, {verification_nonce: verificationNonce}, null, false, true)
      .then(data => {
        runTimer();
        if (codeInput?.current) {
          codeInput?.current.focus()
        }
      })
      .catch(error => {
        setMessage('Не удалось запросить код, попробуйте ещё раз');
        setMessageVisible(true);
      });
  }

  return (
    <>
      <MessageModal title={message}
                    type={SUCCESS}
                    visible={messageVisible}
                    setVisible={setMessageVisible}/>
      <MessageModal title={modalTitle}
                    maskClosable={false}
                    visible={modalVisible}
                    setVisible={setModalVisible}>
        <ManageVerificateEmailWrapper>
          <Form onFinish={sendVerificationCode}
                form={vem_form}>
            <Form.Item key="verification_code"
                       name="verification_code"
                       style={{marginTop: 20}}
                       rules={rules}>
              <Input className="verificate-code-input" placeholder={'000000'} maxLength={6} ref={codeInput}/>
            </Form.Item>
            {disableResend && (
              <div className="verificate-countdown">
                <>Получить код повторно через&nbsp;<Countdown ref={countdownTimer}
                                                              autoStart={false}
                                                              onComplete={() => setDisableResend(false)}
                                                              renderer={props => {
                                                                return <span>{props.formatted?.minutes}:{props.formatted?.seconds}</span>
                                                              }}
                                                              date={dateNow + 60000} /></>
              </div>
            )}


            <Form.Item style={{width: '100%'}}>
              <ManageVerificateEmailButtons>
                <div className="left-column"><button className="btn" type="submit">Продолжить</button></div>
                <div className="right-column"><Button className="btn2 payup" disabled={disableResend} onClick={resendCode}>Получить код ещё раз</Button></div>

              </ManageVerificateEmailButtons>
            </Form.Item>
          </Form>
        </ManageVerificateEmailWrapper>

      </MessageModal>
    </>
  )
}

export default VerificateCodeModal;