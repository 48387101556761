import React, { useState } from "react";
import {MessageModalWrapper} from "./Layout.style";
import {SUCCESS} from "../../helpers/common";
import {CustomModal} from "../Utils/CustomModal";


const MessageModal = ({ title = 'Спасибо',
                        description = null,
                        type = null,
                        additionalClass = null,
                        maskClosable = true,
                        isVacancy = false,
                        titleInChildren = false,
                        internalPadding = false,
                        visible, setVisible, children, }) => {

  const isSuccess = type === SUCCESS;

  return (
    <CustomModal
      centered
      maskClosable={maskClosable}
      visible={visible}
      onOk={ () => setVisible(false) }
      onCancel={ () => setVisible(false) }
      className={`quest-modal sm-modal-contant ${!internalPadding ? 'add-padding' : ''} ${additionalClass}`}
      style={{ maxWidth: isVacancy ? 540 : 854 }}
    >
      {
        (isSuccess || children) && (
          <div className="modal-close modal-message-close" onClick={ () => setVisible(false) }/>
        )
      }
      {
        isSuccess && (
          <MessageModalWrapper className="thanks-modal__wp">
            <h2>{ title }</h2>
            <div className="thanks-modal__img"><img src={`/images/home/success-icon.svg`} /></div>
            { description && description?.map( (it, index) => <div id={`thanks-modal-text1`} className="p2-centered p2" dangerouslySetInnerHTML={{ __html: it}} />) }
          </MessageModalWrapper>
        )
      }
      {
        (!isSuccess && !children) && (
          <MessageModalWrapper className="opt-modal__wp" style={{ padding: internalPadding && '40px 56px 50px'}}>
            <h2 className="opt-modal-error-title">{ title }</h2>
            {
              description && (
                description?.map( (it, index) => <div id={`thanks-modal-text1`} className="p2-centered p2" dangerouslySetInnerHTML={{ __html: it}} />)
              )
            }
          </MessageModalWrapper>
        )
      }
      {
        children && (
          <MessageModalWrapper className="opt-modal__wp">
            {titleInChildren ? (
              <>
                { children }
              </>
            ) : (
              <>
                <h2>{ title }</h2>
                { children }
              </>
            )}
          </MessageModalWrapper>
        )
      }
    </CustomModal>
  )
}

export default MessageModal;