import Header from "../Section/Header";
import React, { useEffect } from "react";
import AOS from "aos";
import Cookies from "js-cookie";
import Head from "next/head";
import {LayoutWrapper} from "./Layout.style";
import Footer from "../Section/Footer";
import CookiePolicyBox from '../Utils/CookiePolicyBox';

const Layout = ({ isOrangeLogo, backgroundColor, title = null, children, isNoIndex = false }) => {
  useEffect(() => {
    AOS.init({
      disable: true,
      //startEvent: 'load',
      once: true
    });
    Cookies.set('vl_rpu', window.document.referrer, { expires: 365 })
  }, []);

  return (
    <>
      <Head>
        <title>{ title ? `${title} - ` : '' }Facancy</title>
        {isNoIndex && (
          <meta name="robots" content="noindex" />
        )}
      </Head>
      <LayoutWrapper className="wrapper">
        <Header isOrangeLogo={isOrangeLogo} backgroundColor={backgroundColor} />
        { children }
        <Footer />
        <CookiePolicyBox />
      </LayoutWrapper>
    </>
  )
}

export default Layout;