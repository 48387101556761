import styled from "styled-components";
import {Modal} from "antd";
import {VacancyFormWrapper} from "./VacancyForm/VacancyFom.style";

export const CustomModal = styled(Modal)`
  max-width: 1440px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  transform: translate(0);
  background-color: #fff;
  cursor: default;
  
  &.ant-modal {
    width: calc(100% - 40px) !important;
    padding-bottom: 0;

    &.ant-modal-extended {
      max-width: 1440px;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      display: none;
    }

    .ant-modal-close-x {
      display: none;
    }
  }

  &.add-padding {
    padding: 40px 56px 50px;
  }

  &.signin-modal {
    .btn2 {
      padding: 0;
    }

    .btn2 a {
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .btn2 a:hover {
      border-color: #EF6957;
      color: #EF6957
    }
  }

  .modal-close {
    width: 34px;
    height: 34px;
    top: 20px;
    right: 20px;
    background: #F5F7FB;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    transition: all .2s linear;
    
    &.modal-message-close {
      top: -20px;
      right: -35px;
    }
    
    :after,
    :before {
      content:"";
      position:absolute;
      top:50%;
      left:50%;
      height:2px;
      background-color:#000;
      width: 65%;
    }
  }
  
  .modal-close:before{transform: translate(-50%,-50%) rotate(45deg);}
  .modal-close:after{transform:translate(-50%,-50%) rotate(-45deg)}
  
  .modal-close:hover{transform:scale(1.1)}

  &.opt-modal {
    h2 {
      margin-bottom: 32px
    }
    
    .p2, h2 {
      text-align: center
    }

    .btn,.btn2 {
      width: 48%;
      max-width: 310px;
      padding-left: 15px;
      padding-right: 15px
    }
  }
  
  &.no-money-modal {
    .btn {
      display: -ms-flexbox;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 310px;
      margin-top: 48px
    }

    h2 svg {
      margin-right: 12px
    }
  }
  
  .opt-modal__bottom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
    max-width: 643px;
    
    &.button-center {
      justify-content: center;
    }
  }

  .thanks-modal__wp {
    flex-direction: column
  }

  .thanks-modal__img,
  .thanks-modal__wp {
    display: flex;
    align-items: center
  }

  .thanks-modal__img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0 4px 16px #EEF2F9;
    margin-top: 32px;
    margin-bottom: 20px;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }

  
  @media (min-width: 1441px) {
    &.opt-modal {
      .btn, .btn2 {
        font-size:20px;
        line-height: 24px;
        height: 64px
      }
    }
  }

  @media (max-width: 990px) {
    &.opt-modal {
      h2 {
        margin-bottom:15px
      }
    }

    .opt-modal__bottom {
      margin-top: 23px
    }

    &.no-money-modal {
      h2 svg {
        width: 22px;
        height: 20px;
        margin-right: 7px
      }

      .btn {
        margin-top: 25px
      }
    }
  }

  @media (max-width: 760px) {
    .thanks-modal__img {
      margin-top: 20px;
      margin-bottom: 6px
    }
    
    .modal-close {
      top: 16px;
      right: 16px
    }
  }

  @media (max-width: 575px) {
    .opt-modal__bottom {
      display: block
    }

    .opt-modal__bottom .btn,
    .opt-modal__bottom .btn2 {
      width: 100%;
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      display: -ms-flexbox;
      display: flex;
      margin-top: 10px;
      height: 40px;
    }
  }

  @media (max-width: 480px){
    &.add-padding {
      padding: 28px 24px 36px;
    }

    .modal-close {
      width: 24px;
      height: 24px;
    }

    &.add-padding .modal-close {
      top: -18px!important;
      right: -16px!important;
    }
  }

  @media (max-width: 375px){
    &.opt-modal .btn,
    &.opt-modal .btn2 a {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  ${VacancyFormWrapper} {
    &.vacancy__question-wp {
      padding-top: 60px;
      padding-bottom: 60px
    }

    @media (max-width: 760px) {
      &.vacancy__question-wp {
        padding: 57px 16px 30px
      }
    }
  }

`