import React, {useEffect, useState} from "react";
import MessageModal from "../Layout/MessageModal";
import {ERROR, ERROR_CODES, SUCCESS, TOKEN} from "../../helpers/common";
import {Form, Input} from "antd";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Link from "next/link";
import api from "../../api";
import VerificateCodeModal from "./VerificateCodeModal";
import Cookies from "js-cookie";
import {useRouter} from "next/router";
import CustomLoader from "./CustomLoader";


export const SignInButton = ({ isSmall = true }) => {

  const [showAuthModal, setShowAuthModal] = useState(false);

  return (
    <>
      <SignInModal showAuthModal={showAuthModal} setShowAuthModal={setShowAuthModal} isSmall={isSmall}/>
      <button className={`btn ${!isSmall ? '' : ' header-button'}`} type={'button'} onClick={() => setShowAuthModal(true)}>
        <span className="subscription-form-text saving-text">Войти</span>
      </button>
    </>

  )
}

export const SignInModal = ({ initialValues = {},  onCallback = null,
                              title = "У вас есть подписка с указанным e-mail'ом на Facancy, предлагаем вам авторизоваться на сайте",
                              showAuthModal, setShowAuthModal, isSmall, isOnlyAuth = false}) => {

  const router = useRouter();
  const [form] = Form.useForm();


  const [messageType, setMessageType] = useState(SUCCESS);

  const [verificationModalVisible, setVerificationModalVisible] = useState(false);
  const [verificationNonce, setVerificationNonce] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  const [message, setMessage] = useState(null);
  const [messageVisible, setMessageVisible] = useState(false);

  const [registrationUrl, setRegistrationUrl] = useState('/vacancies-subscription#subscription-form');
  const [isRegistrationAnchor, setIsRegistrationAnchor] = useState(false);


  useEffect(() => {
    if (router?.pathname === '/vacancies-subscription' || (router?.pathname === '/vacancies/[slug]')) {
      setRegistrationUrl('#subscription-form');
      setIsRegistrationAnchor(true)
    }
  }, [router])

  useEffect(() => {
    if (showAuthModal) {
      form.setFieldValue("auth_email", initialValues?.auth_email || "")
    }
  }, [form, showAuthModal, initialValues])

  const handleLogin = async (values) => {
    setIsLogin(true)
    try {
      const data = await api.post('/auth/signin', {}, {email: values.auth_email}, null, false, false)

      setIsLogin(false);
      setMessageType(SUCCESS)
      setVerificationNonce(data?.data?.verification_nonce)
      setVerificationModalVisible(true)
      setShowAuthModal(false)
    } catch (error) {
      setIsLogin(false)
      setMessageType(ERROR)
      const errorMessage = {
        [ERROR_CODES.auth_email_not_exists]: 'Не удалось отправить код на указанный почтовый адрес'
      }[error.code] || error.message || 'Не удалось авторизоваться'

      setMessage(errorMessage);
      setMessageVisible(true);
    }
  }

  return (
    <>
      <VerificateCodeModal modalVisible={verificationModalVisible}
                           setModalVisible={setVerificationModalVisible}
                           modalTitle="Для того, чтобы продолжить авторизацию, введите код, который был отправлен на ваш email:"
                           verificationNonce={verificationNonce}
                           resendUrl={'/auth/verification/resend_code'}
                           sendCodeUrl={'/auth/verification/confirm_operation'}
                           successModalMessage={null}
                           onSuccess={(data) => {
                             Cookies.set(TOKEN, data.access_token, {expires: 365 * 10, httpOnly: false})
                             if (!isOnlyAuth) {
                               if (isSmall) {
                                 router.reload()
                               } else {
                                 router.push('/vs-manage?tabValue=tariffs')
                               }
                             } else {
                               setMessage("Вы успешно авторизовались в системе")
                               setMessageVisible(true)
                               setMessageType(SUCCESS)
                               onCallback?.()
                               // setTimeout(() => {
                               //   setMessage(null)
                               //   setMessageVisible(false)
                               // }, 5000)
                             }
                             //setVerificationModalVisible(false)
                           }}/>

      <MessageModal title={message}
                    type={messageType}
                    visible={messageVisible}
                    setVisible={() => {
                      setMessageVisible(false)
                    }}/>

      <MessageModal title={isOnlyAuth ? title : "Добро пожаловать в Facancy"} additionalClass={"opt-modal signin-modal"}
                    visible={showAuthModal} setVisible={() => {
                      setShowAuthModal(false)
                      onCallback?.()
      }}>
        <Form form={form} initialValues={initialValues} onFinish={handleLogin}>
          <Form.Item key="auth_email"
                     name="auth_email"
                     rules={[{
                       required: true,
                       message: '',
                     }]}
                     style={{marginTop: 20, marginBottom: '-12px'}}>
            <Input type={"email"} className={"input-block input-full-width"} placeholder={'Адрес электронной почты'}/>
          </Form.Item>
          <Form.Item>
            <div className={ "opt-modal__bottom" }>
              <button type="submit" id="b-signin" className="btn">
                <CustomLoader isActive={isLogin}/>
                <span id="b-disable-option-immediate-vacancies-text" style={{ display: !isLogin ? 'flex' : 'none' }}>
                    Войти
                  </span>
              </button>

              {isOnlyAuth ? (
                <button type="button" className="btn2" onClick={ () => {
                  setShowAuthModal(false)
                  onCallback?.()
                } }>
                  Отменить
                </button>
              ) : (
                <>
                  {isRegistrationAnchor ? (
                    <button type="button" className="btn2 close-mod" onClick={ () => setShowAuthModal(false) }>
                      <AnchorLink href={registrationUrl} style={{flex: 1}}>
                        Зарегистрироваться
                      </AnchorLink>
                    </button>

                  ) : (
                    <button className="btn2 close-mod">
                      <Link href={registrationUrl} className={"vacancy-link"}>Зарегистрироваться</Link>
                    </button>
                  )}
                </>
              )}
            </div>
          </Form.Item>
        </Form>
      </MessageModal>
    </>

  )
}
